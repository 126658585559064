import React, { useState, useEffect } from "react";
import MiniButton from "new-ui/Button/MiniButton";
import IconClose from "new-ui/Icons/IconClose";
import IconWarning from "new-ui/Icons/IconWarning";
import IconMessage from "new-ui/Icons/IconMessage";

const Snackbar = ({
  title,
  children,
  open,
  variant = "warning",
  action,
  timeout, //ms
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    let timer;
    setShow(open);
    if (timeout) {
      timer = setTimeout(() => setShow(false), timeout);
    }

    return () => clearTimeout(timer);
  }, [open, timeout]);

  if (!show) {
    return null;
  }

  return (
    <aside
      className={`w-100 mt2 fade-in-animation flex items-start flex-wrap ${getClassName(
        variant
      )}`}
    >
      <div className="ma2">{getIcon(variant)}</div>
      <div className="flex-1 pa2">
        {title && (
          <h1 className="ma0 f1 b" style={{ lineHeight: "24px" }}>
            {title}
          </h1>
        )}
        <p className="ma0" style={{ lineHeight: "24px" }}>
          {children}
        </p>
        {action && <div className="mt3">{action}</div>}
      </div>
      <CloseButton variant={variant} onClick={() => setShow(false)} />
      {timeout && (
        <div className="w-100">
          <ProgressBar duration={timeout} />
        </div>
      )}
    </aside>
  );
};

const CloseButton = ({ variant, onClick }) => (
  <MiniButton
    onClick={onClick}
    variant={variant === "warning" ? "textNegative" : "text"}
  >
    <IconClose />
  </MiniButton>
);

const ProgressBar = ({ duration }) => (
  <div
    className="bg-primary w-100 linear-progress-animation"
    style={{ height: 2, animationDuration: `${duration}ms` }}
  />
);

const getClassName = (variant) =>
  ({
    message: "bg-foreground ba b--primary",
    warning: "bg-error c-background",
  }[variant]);

const getIcon = (variant) =>
  ({
    message: <IconMessage />,
    warning: <IconWarning />,
  }[variant]);

export default Snackbar;
