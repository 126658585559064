import { LENGHT_UNIT_SET, FORCE_UNIT_SET } from "./constants";

export function setLengthUnit(unit) {
  return {
    unit,
    type: LENGHT_UNIT_SET,
  };
}

export function setForceUnit(unit) {
  return {
    unit,
    type: FORCE_UNIT_SET,
  };
}
