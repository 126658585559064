import React from "react";
import BackgroundOverlay from "./BackgroundOverlay";

const Dialog = ({ children }) => (
  <div className="h-100 w-100 fixed top-0 left-0 z-max">
    <BackgroundOverlay />
    {children}
  </div>
);
export default Dialog;
