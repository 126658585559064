import React from "react";
import useUser from "containers/User/hooks/useUser";
import BlockedRoute from "./BlockedRoute";

const SubscribedRoute = ({ children, ...rest }) => {
  const { loading, isAuthenticated, isSubscriptionValid } = useUser();

  return (
    <BlockedRoute
      block={!isAuthenticated || !isSubscriptionValid}
      loading={loading}
      redirectTo="/menu"
      {...rest}
    >
      {children}
    </BlockedRoute>
  );
};

export default SubscribedRoute;
