import ToggleButton from "new-ui/Button/ToggleButton";
import React from "react";

const UnitButton = ({ unit, selectedUnit, onClick }) => {
  return (
    <ToggleButton
      isSelected={unit === selectedUnit}
      onClick={() => onClick(unit)}
    >
      {unit}
    </ToggleButton>
  );
};

export default UnitButton;
