import * as immutable from "object-path-immutable";
import {
  GET_RESULTS_ITEM_SUCCESS,
  NEW_FILE,
} from "containers/Toolbar/constants";
import {
  SECTION_REQUEST,
  SECTION_SUCCESS,
  SECTION_FAILURE,
  SECTION_FIELD_SET,
} from "./constants";

const initialState = {
  input: {
    sectionType: "rsection",
    useFlange: false,
    bw: "",
    h: "",
    bf: "",
    hf: "",
    Md: "",
    Vd: 0,
    fck: 30,
    fyk: 500,
    gammaC: 1.4,
    gammaS: 1.15,
    coverage: 0.03,
    fiTrac: null,
    nrebarsTrac: null,
    fiStirrup: null,
    spacing: null,
    nbranches: null,
    flexuralDetailingMode: "auto", // auto or manual
    shearDetailingMode: "auto", // auto or manual
  },
  filter: {
    showDimLines: true,
    showForces: true,
    showEffectiveHeight: true,
  },
  results: {},
  error: null,
  fetching: false,
};

export default function sectionReducer(state = initialState, action) {
  switch (action.type) {
    case SECTION_REQUEST:
      return {
        ...state,
        error: null,
        fetching: true,
      };
    case SECTION_SUCCESS:
      const inputWithDetailing = action.response.input;
      const {
        fiTrac,
        nrebarsTrac,
        fiStirrup,
        spacing,
        nbranches,
      } = inputWithDetailing;

      return {
        ...state,
        input: {
          ...state.input,
          fiTrac: fiTrac,
          nrebarsTrac: nrebarsTrac,
          fiStirrup: fiStirrup,
          spacing: spacing,
          nbranches: nbranches,
        },
        error: null,
        fetching: false,
        results: {
          ...action.response.design,
          input: action.response.input,
        },
      };
    case SECTION_FAILURE:
      return {
        ...state,
        error: `Erro ao calcular seção ${action.status}`,
        fetching: false,
      };
    case SECTION_FIELD_SET:
      return immutable.set(state, action.path, action.value);

    case GET_RESULTS_ITEM_SUCCESS: {
      if (action.response.type !== "SECTION") {
        return state;
      }

      return {
        ...state,
        error: null,
        fetching: false,
        results: {
          ...action.response.results_data,
        },
        input: {
          ...action.response.results_data.input,
        },
      };
    }

    case NEW_FILE: {
      if (action.results_type === "SECTION") {
        return initialState;
      }
      return state;
    }

    default:
      return state;
  }
}
