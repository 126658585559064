export const BEAM_REQUEST = "BEAM_REQUEST";
export const BEAM_SUCCESS = "BEAM_SUCCESS";
export const BEAM_FAILURE = "BEAM_FAILURE";
export const BEAM_FLEXURAL_PARAMS_SET = "BEAM_FLEXURAL_PARAMS_SET";
export const BEAM_CUT_POSITION_SET = "BEAM_CUT_POSITION_SET";
export const BEAM_SHEAR_PARAMS_SET = "BEAM_SHEAR_PARAMS_SET";
export const BEAM_SKIN_PARAMS_SET = "BEAM_SKIN_PARAMS_SET";
export const BEAM_STIRRUP_HOLDER_PARAMS_SET = "BEAM_STIRRUP_HOLDER_PARAMS_SET";
export const BEAM_DXF_REQUEST = "BEAM_DXF_REQUEST";
export const BEAM_SET_INPUT_FIELD = "BEAM_SET_INPUT_FIELD";
export const BEAM_FIELD_PUSH = "BEAM_FIELD_PUSH";
export const BEAM_FIELD_DELETE = "BEAM_FIELD_DELETE";
