import "whatwg-fetch";

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  return response.json();
}

/**
 * Parses the FILE returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed blob from the request
 */
function parseBlob(response) {
  return response.blob();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  return response.json().then((json) =>
    Promise.reject({
      status: response.status,
      ok: false,
      statusText: response.statusText,
      ...json,
    })
  );
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  const headers = (options && options.headers) || {};

  return fetch(url, {
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    ...options,
  })
    .then(checkStatus)
    .then((response) => {
      const mimetype = response.headers.get("content-type");
      if (mimetype === "application/dxf") {
        return parseBlob(response);
      }
      return parseJSON(response);
    });
}
