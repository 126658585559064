import { Auth } from "aws-amplify";

export async function getAuthHeader() {
    try {
      // Calling Auth.currentSession will refresh tokens \o/
      let session = await Auth.currentSession();
      // User is logged in.
      return "Bearer " + session.idToken.jwtToken;
    } catch (error) {
      // No logged-in user: don't set auth header
      return "";
    }
  }