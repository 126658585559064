import React, { useEffect, Suspense, lazy } from "react";
import { Router, Route, Switch, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import ReactGA from "react-ga";
import history from "./history";
import configureStore from "./store";
import WithTempFrame from "containers/App/WithTempFrame";
import LoadingDialog from "containers/Toolbar/LoadingDialog";
import SubscribePopup from "containers/Subscribe/SubscribePopup";
import useSetUser from "containers/User/hooks/useSetUser";
import AuthenticatedRoute from "containers/App/AuthenticatedRoute";
import SubscribedRoute from "containers/App/SubscribedRoute";
import Educational from "containers/Educational";
import TermsOfService from "containers/TermsOfService";
import PrivacyPolicy from "containers/PrivacyPolicy";
import useRedirectUser from "containers/User/hooks/useRedirectUser";

const Home = lazy(() => import("containers/Home"));
const Menu = lazy(() => import("containers/Menu"));
const About = lazy(() => import("containers/About"));
const Contact = lazy(() => import("containers/Contact"));
const PileCap = lazy(() => import("containers/PileCap"));
const Section = lazy(() => import("containers/Section"));
const Beam = lazy(() => import("containers/Beam"));
const StructuralModel = lazy(() => import("containers/StructuralModel"));
const Checkout = lazy(() => import("containers/Checkout"));

const store = configureStore({}, history);

const App = () => (
  <Provider store={store}>
    <Router history={history}>
      <Suspense fallback={<LoadingDialog />}>
        <Routes />
      </Suspense>
    </Router>
    <SubscribePopup />
  </Provider>
);

const Routes = () => {
  let { pathname } = useLocation();
  useSetUser();
  useRedirectUser();

  useEffect(() => {
    ReactGA.pageview(pathname);
  }, [pathname]);

  return (
    <Switch>
      <Route exact path="/sobre">
        <About />
      </Route>
      <Route exact path="/contato">
        <Contact />
      </Route>
      <Route exact path="/termos-de-servico">
        <TermsOfService />
      </Route>
      <Route exact path="/politica-de-privacidade">
        <PrivacyPolicy />
      </Route>
      <AuthenticatedRoute exact path="/menu">
        <Menu />
      </AuthenticatedRoute>
      <SubscribedRoute exact path="/bloco">
        <PileCap />
      </SubscribedRoute>
      <AuthenticatedRoute exact path="/secao">
        <Section />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path="/viga"
        component={WithTempFrame(Beam, "BEAM")}
      />
      <AuthenticatedRoute exact path="/modelo-estrutural">
        <StructuralModel />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/checkout">
        <Checkout />
      </AuthenticatedRoute>
      <Route path="/" component={Home} />
    </Switch>
  );
};

export default App;
