import React from "react";
import Dialog from "new-ui/Dialog";
import MiniButton from "new-ui/Button/MiniButton";
import IconClose from "new-ui/Icons/IconClose";
import Button from "new-ui/Button/Button";

const ConfirmationDialog = ({
  onConfirm,
  onCancel,
  message,
  confirmButtonLabel,
}) => (
  <Dialog>
    <div className="w-100 h-100 flex items-center justify-center pa3">
      <div className="w-100 bg-background ba pa3 mw6">
        <MiniButton onClick={onCancel}>
          <IconClose />
        </MiniButton>
        <div className="pv3">
          <p className="lh-copy">{message}</p>
        </div>
        <div className="tr">
          <Button variant="outlined" onClick={onConfirm}>
            {confirmButtonLabel}
          </Button>
        </div>
      </div>
    </div>
  </Dialog>
);

export default ConfirmationDialog;
