import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import LinkButton from "new-ui/Button/LinkButton";

const AcessoryPageTemplate = ({ children, title }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <Helmet>
        <title>{`${title} - Estruto`}</title>
      </Helmet>
      <header className="pa3 bb b--border">
        <div className="mw8 center">
          <LinkButton path="/menu">Voltar</LinkButton>
        </div>
      </header>
      <div className="pa3 mw8 center">
        <h1 className="f2 fw9">{title}</h1>
        {children}
      </div>
    </main>
  );
};

export default AcessoryPageTemplate;
