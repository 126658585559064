import React from "react";
import useWindowSize from "hooks/useWindowSize";

const WarningsContainer = ({ children }) => {
  const windowSize = useWindowSize();

  return (
    <div
      className="absolute bottom-0 left-0 w-100 mw6 pa2 pa3-ns no-print"
      style={{ maxWidth: windowSize === "medium" && "50%" }}
    >
      {children}
    </div>
  );
};

export default WarningsContainer;
