import { call, put, takeLatest } from "redux-saga/effects";
import request from "utils/request";
import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from "./constants";

export function* registerEmail({ type, email }) {
  try {
    // eslint-disable-next-line
    const results = yield call(request, "/api/register", {
      method: "POST",
      body: JSON.stringify({ email }),
    });

    yield put({
      type: REGISTER_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: REGISTER_FAILURE,
      status: err.status,
    });
  }
}

export function* watchRegisterEmail() {
  yield takeLatest(REGISTER_REQUEST, registerEmail);
}

export default [watchRegisterEmail];
