import { take, call, put, takeLatest, select } from "redux-saga/effects";
import request from "utils/request";
import { getAuthHeader } from "../Utils/Auth"
import { SECTION_REQUEST, SECTION_SUCCESS, SECTION_FAILURE } from "./constants";

export function* fetchSection({ type, params }) {
  try {
    const authHeader = yield call(getAuthHeader);

    const response = yield call(request, "/api/sections", {
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ ...params }),
    });

    yield put({
      response,
      type: SECTION_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: SECTION_FAILURE,
      status: err.status,
    });
  }
}

export function* watchFetchSection() {
  yield takeLatest(SECTION_REQUEST, fetchSection);
}

export default [watchFetchSection];
