import { getDefaultSection } from "./defaultSections";

const example = {
  nodes: [
    {
      x: -2,
      y: 0,
      rx: true,
      ry: true,
      rz: false,
      ux: 0,
      uy: 0,
      uz: 0,
      sx: 0,
      sy: 0,
      sz: 0,
    },
    {
      x: -2,
      y: 3,
      rx: false,
      ry: false,
      rz: false,
      ux: 0,
      uy: 0,
      uz: 0,
      sx: 0,
      sy: 0,
      sz: 0,
    },
    {
      x: 2,
      y: 3,
      rx: false,
      ry: false,
      rz: false,
      ux: 0,
      uy: 0,
      uz: 0,
      sx: 0,
      sy: 0,
      sz: 0,
    },
    {
      x: 2,
      y: 0,
      rx: true,
      ry: true,
      rz: false,
      ux: 0,
      uy: 0,
      uz: 0,
      sx: 0,
      sy: 0,
      sz: 0,
    },
  ],
  members: [
    {
      n1: 0,
      n2: 1,
      type: "0",
      leftHinge: false,
      rightHinge: false,
      sectionId: 0,
    },
    {
      n1: 1,
      n2: 2,
      type: "0",
      leftHinge: false,
      rightHinge: false,
      sectionId: 0,
    },
    {
      n1: 3,
      n2: 2,
      type: "0",
      leftHinge: false,
      rightHinge: false,
      sectionId: 0,
    },
  ],
  nodeForces: [
    {
      loadCase: 2,
      node: 2,
      fx: 100,
      fy: 0,
      mz: 0,
    },
    {
      loadCase: 0,
      node: 1,
      fx: 0,
      fy: 0,
      mz: -20,
    },
    {
      loadCase: 1,
      node: 2,
      fx: 50,
      fy: 0,
      mz: 0,
    },
  ],
  memberLoads: [
    {
      loadCase: 0,
      member: 1,
      qz: -100,
    },
    {
      loadCase: 2,
      member: 1,
      qz: -50,
    },
  ],
  sections: [
    getDefaultSection("r"),
    getDefaultSection("c"),
    getDefaultSection("t"),
    getDefaultSection("g"),
  ],
  loadCases: ["pp", "perm", "sc"],
  combinations: [
    {
      name: "Perm",
      factors: [1, 1, 0],
    },
    {
      name: "ELU",
      factors: [1.3, 1.4, 1.5],
    },
  ],
};

export default example;
