import React from "react";
import AcessoryPageTemplate from "new-ui/PageLayout/AcessoryPageTemplate";

const PrivacyPolicy = () => (
  <AcessoryPageTemplate title="Política de Privacidade">
    <div className="lh-copy measure-wide">
      <p>
        SEÇÃO 1 - O QUE FAZEMOS COM AS SUAS INFORMAÇÕES? Quando você compra
        alguma coisa na nossa loja, como parte do processo de compra e venda,
        coletamos as informações pessoais que você nos fornece, tais como seu
        nome, endereço e e-mail. Quando você navega pela nossa loja, recebemos
        também automaticamente o protocolo de internet do seu computador
        (endereço de IP) a fim de obter informações que nos ajudam a saber mais
        sobre seu navegador e sistema operacional. Marketing por e-mail (quando
        aplicável): Com sua permissão, podemos lhe enviar e-mails sobre nossa
        loja, novos produtos e outras atualizações.
      </p>
      <p>
        SEÇÃO 2 - CONSENTIMENTO Como vocês obtêm meu consentimento? Quando você
        nos fornece as suas informações pessoais para completar uma transação,
        verificar seu cartão de crédito, fazer um pedido, providenciar uma
        entrega ou retornar uma compra, você está concordando com a nossa coleta
        e uso de informações pessoais apenas para esses fins específicos. Se
        pedirmos suas informações pessoais por uma razão secundária, como
        marketing, vamos pedir seu consentimento, ou te dar a oportunidade de
        dizer não. Como posso retirar o meu consentimento? Caso depois de
        fornecer seus dados você mude de ideia, você pode retirar o seu
        consentimento quando quiser e assim evitar que entremos em contato para
        obter ou divulgar informações. Entre em contato conosco através do
        estruto@estruto.com ou por correio: Estruto
      </p>
      <p>
        SEÇÃO 3 - DIVULGAÇÃO Podemos divulgar suas informações pessoais se
        formos obrigados por lei a fazê-lo ou se você violar nossos Termos de
        serviço.
      </p>
      <p>
        SEÇÃO 4 - SERVIÇOS DE TERCEIROS No geral, nossos fornecedores
        terceirizados irão coletar, usar e divulgar suas informações apenas na
        medida do necessário para permitir que eles realizem os serviços que
        eles nos fornecem. Entretanto, certos prestadores de serviços
        terceirizados, tais como gateways de pagamento e outros processadores de
        transações de pagamento, têm suas próprias políticas de privacidade em
        relação à informação que somos obrigados a fornecer para eles sobre
        transações relacionadas a compras. Para esses fornecedores, recomendamos
        que você leia suas políticas de privacidade para que você possa entender
        de que maneira suas informações pessoais serão usadas por esses
        fornecedores. Especificamente, lembre-se que certos fornecedores podem
        estar localizados ou possuir instalações que ficam em jurisdições
        diferentes da sua ou da nossa. Por isso, se você quiser continuar com
        uma transação que envolva um prestador de serviços terceirizado, suas
        informações podem ficar sujeitas à legislação da(s) jurisdição(ões) onde
        o prestador de serviços ou suas instalações estiverem localizados. Por
        exemplo, se você está no Canadá e sua transação é processada por um
        gateway de pagamento nos Estados Unidos, suas informações pessoais
        usadas para completar a transação podem estar sujeitas a divulgação sob
        a legislação dos Estados Unidos, incluindo a Lei Patriótica. Uma vez que
        você sair do site da nossa loja ou for redirecionado para um aplicativo
        ou site de terceiros, você não será mais regido por essa Política de
        privacidade ou pelos Termos de serviço do nosso site. Links Quando você
        clicar em links na nossa loja, eles podem lhe direcionar para fora do
        nosso site. Não somos responsáveis pelas práticas de privacidade de
        outros sites e lhe incentivamos a ler as políticas de privacidade deles.
      </p>
      <p>
        SEÇÃO 5 - SEGURANÇA Para proteger suas informações pessoais, tomamos
        precauções e seguimos as melhores práticas da indústria para nos
        certificar que elas não sejam perdidas, usurpadas, acessadas,
        divulgadas, alteradas ou destruídas. Se você nos fornecer as suas
        informações de cartão de crédito, elas serão criptografadas usando a
        tecnologia "secure socket layer" (SSL) e armazenadas com criptografia
        AES-256. Embora nenhum método de transmissão pela Internet ou
        armazenamento eletrônico seja 100% seguro, nós seguimos todos os
        requisitos da PCI-DSS e implementamos medidas adicionais aceitas pelos
        padrões da indústria. COOKIES
      </p>
      <p>
        SEÇÃO 6 - IDADE DE CONSENTIMENTO Ao usar esse site, você confirma que
        você é maior de idade em seu estado ou província de residência e que
        você nos deu seu consentimento para permitir que qualquer um dos seus
        dependentes menores de idade usem esse site.
      </p>
      <p>
        SEÇÃO 7 - ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE Reservamos o direito de
        modificar essa política de privacidade a qualquer momento. Portanto, por
        favor, leia-a com frequência. As alterações e esclarecimentos surtem
        efeito imediatamente após serem publicadas no site. Caso façamos
        alterações na política de privacidade, iremos notificá-lo sobre a
        atualização. Assim, você saberá quais informações coletamos, como as
        usamos, e sob que circunstâncias, caso aplicável, as utilizaremos e/ou
        divulgaremos. Caso ocorra a fusão da nossa loja com outra empresa, suas
        informações podem ser transferidas para os novos proprietários para que
        possamos continuar vendendo produtos para você.
      </p>
      <p>
        PERGUNTAS E INFORMAÇÕES DE CONTATO Se você gostaria de acessar,
        corrigir, alterar ou excluir quaisquer informações pessoais que temos
        sobre você, registre uma queixa, ou simplesmente peça mais informações
        de contato a(o) nosso(a) Diretor(a) de privacidade através do
        estruto@estruto.com.
      </p>
    </div>
  </AcessoryPageTemplate>
);

export default PrivacyPolicy;
