import React from "react";
import ButtonBase from "./ButtonBase";
import getClassName from "./style";

const Button = ({
  className,
  children,
  variant,
  disabled,
  type,
  onClick,
  noPadding,
}) => {
  return (
    <ButtonBase
      className={`ph3 ${getClassName(variant, disabled)} ${className}`}
      disabled={disabled}
      onClick={onClick}
      type={type}
      noPadding={noPadding}
    >
      {children}
    </ButtonBase>
  );
};

export default Button;
