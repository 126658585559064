import * as immutable from "object-path-immutable";
import cloneDeep from "lodash.clonedeep";
import {
  GET_RESULTS_ITEM_SUCCESS,
  NEW_FILE,
} from "containers/Toolbar/constants";
import {
  BEAM_REQUEST,
  BEAM_SUCCESS,
  BEAM_FAILURE,
  BEAM_FLEXURAL_PARAMS_SET,
  BEAM_CUT_POSITION_SET,
  BEAM_SHEAR_PARAMS_SET,
  BEAM_SKIN_PARAMS_SET,
  BEAM_STIRRUP_HOLDER_PARAMS_SET,
  BEAM_SET_INPUT_FIELD,
  BEAM_FIELD_PUSH,
  BEAM_FIELD_DELETE,
} from "./constants";

const initialState = {
  results: {},
  settings: {
    columnWidth: 0.3,
    fck: 30,
    coverage: 0.03,
    unit: "m",
  },
  error: null,
  fetching: false,
  section: {
    base: 0.25,
    height: 0.7,
    useFlange: false,
    type: "rsection",
    flangeWidth: "",
    flangeHeight: "",
  },
  diagram: [
    {
      x1: 0,
      x2: 2.5,
      x3: 5.0,
      m1: 0,
      m2: 200,
      m3: -150,
    },
  ],
  flexuralParams: null,
  shearParams: null,
  skinParams: null,
  stirrupHolderParams: null,
  cutPosition: null,
  /**
   * Chartjs will mutate the data objects we pass them.
   * This makes it difficult for us to save/load results
   * to/from our db.
   * Here we store deep copies of our data objects for them
   * to play with.
   * While keeping the data returned from server untouched.
   */
  chartjs: {
    cutPosition: null,
    bending_diagram_chartjs: null,
    bending_diagram_positions_chartjs: null,
    shear_diagram_chartjs: null,
    shear_zones_chartjs: null,
  },
};

export default function beamReducer(state = initialState, action) {
  switch (action.type) {
    case BEAM_REQUEST:
      return {
        ...state,
        error: null,
        fetching: true,
      };
    case BEAM_SUCCESS:
      return {
        ...state,
        error: null,
        fetching: false,
        results: {
          ...action.results,
        },
        flexuralParams: action.results.flexural_detailing_params,
        shearParams: action.results.shear_detailing_params,
        skinParams: action.results.skin,
        stirrupHolderParams: {
          fi: action.results.positions_stirrup_holder_neg[0].fi,
        },
        cutPosition: action.results.section_results.diagram_coordinate,
        chartjs: {
          bending_diagram_chartjs: cloneDeep(
            action.results.bending_diagram_chartjs
          ),
          bending_diagram_positions_chartjs: cloneDeep(
            action.results.bending_diagram_positions_chartjs
          ),
          cutPosition: cloneDeep(
            action.results.section_results.diagram_coordinate
          ),
          shear_diagram_chartjs: cloneDeep(
            action.results.shear_diagram_chartjs
          ),
          shear_zones_chartjs: cloneDeep(action.results.shear_zones_chartjs),
        },
      };
    case BEAM_FAILURE:
      return {
        ...state,
        error: `Error ao calcular viga ${action.status}`,
        fetching: false,
      };
    case BEAM_FLEXURAL_PARAMS_SET:
      return {
        ...state,
        flexuralParams: action.flexuralParams,
      };
    case BEAM_CUT_POSITION_SET:
      return {
        ...state,
        cutPosition: action.cutPosition,
        chartjs: {
          ...state.chartjs,
          cutPosition: action.cutPosition,
        },
      };
    case BEAM_SHEAR_PARAMS_SET:
      return {
        ...state,
        shearParams: action.shearParams,
      };
    case BEAM_SKIN_PARAMS_SET:
      return {
        ...state,
        skinParams: action.skinParams,
      };
    case BEAM_STIRRUP_HOLDER_PARAMS_SET:
      return {
        ...state,
        stirrupHolderParams: action.stirrupHolderParams,
      };
    case BEAM_SET_INPUT_FIELD:
      return immutable.set(state, action.path, action.value);
    case BEAM_FIELD_PUSH:
      return immutable.push(state, action.path, action.value);
    case BEAM_FIELD_DELETE:
      return immutable.del(state, action.path);

    case GET_RESULTS_ITEM_SUCCESS: {
      if (action.response.type !== "BEAM") {
        return state;
      }
      return {
        ...state,
        error: null,
        fetching: false,
        results: {
          ...action.response.results_data,
        },
        flexuralParams: action.response.results_data.flexural_detailing_params,
        shearParams: action.response.results_data.shear_detailing_params,
        skinParams: action.response.results_data.skin,
        stirrupHolderParams: {
          fi: action.response.results_data.positions_stirrup_holder_neg[0].fi,
        },
        cutPosition:
          action.response.results_data.section_results.diagram_coordinate,
        chartjs: {
          bending_diagram_chartjs: cloneDeep(
            action.response.results_data.bending_diagram_chartjs
          ),
          bending_diagram_positions_chartjs: cloneDeep(
            action.response.results_data.bending_diagram_positions_chartjs
          ),
          cutPosition: cloneDeep(
            action.response.results_data.section_results.diagram_coordinate
          ),
          shear_diagram_chartjs: cloneDeep(
            action.response.results_data.shear_diagram_chartjs
          ),
          shear_zones_chartjs: cloneDeep(
            action.response.results_data.shear_zones_chartjs
          ),
        },
        settings: action.response.results_data.input.settings,
        section: action.response.results_data.input.section,
        diagram: action.response.results_data.input.diagram,
      };
    }
    case NEW_FILE: {
      if (action.results_type === "BEAM") {
        return initialState;
      }
      return state;
    }
    default:
      return state;
  }
}
