import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextInput from "new-ui/Input/TextInput";
import Button from "new-ui/Button/Button";
import { validateEmail } from "utils/email";
import { registerEmail } from "./actions";

const SubscribeForm = () => {
  const [email, setEmail] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const { fetching } = useSelector((state) => state.get("subscribe"));
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(registerEmail(email));
  };

  useEffect(() => {
    setCanSubmit(validateEmail(email) && !fetching);
  }, [email, fetching]);

  return (
    <div>
      <h1 className="f2 fw9">Fique por dentro</h1>
      <p className="lh-copy">
        Se você gostou do Estruto e deseja ficar sabendo das novidades assim que
        forem lançadas, cadastre o seu email.
      </p>
      <form className="mt4" onSubmit={handleSubmit}>
        <TextInput
          value={email}
          label="Email"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="email@email.com"
        />
        <div className="tr">
          <Button variant="contained" disabled={!canSubmit}>
            Cadastrar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SubscribeForm;
