import { useState, useEffect } from "react";
import { Hub } from "aws-amplify";
import useUser from "./useUser";
import { useHistory } from "react-router";

const DEFAULT_REDIRECT_PATH = "/menu";

const useRedirectUser = () => {
  const [redirect, setRedirect] = useState(false);
  const [redirectPath, setRedirectPath] = useState(DEFAULT_REDIRECT_PATH);
  const { isAuthenticated } = useUser();
  const history = useHistory();

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          setRedirect(true);
          break;
        case "customOAuthState":
          if (data === "checkout") {
            setRedirectPath("/checkout");
          }
          break;
        default:
          break;
      }
    });
  });

  useEffect(() => {
    if (redirect && isAuthenticated) {
      history.push(redirectPath);
    }
  }, [history, isAuthenticated, redirect, redirectPath]);
};

export default useRedirectUser;
