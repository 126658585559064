import React from "react";
import { useDispatch } from "react-redux";
import { newFile } from "containers/Toolbar/actions";
import ConfirmationDialog from "./ConfirmationDialog";

const NewFileConfirmationDialog = ({ resultsType, onClose }) => {
  const dispatch = useDispatch();

  const handleNewFile = () => {
    dispatch(newFile(resultsType));
    onClose();
  };

  return (
    <ConfirmationDialog
      onConfirm={handleNewFile}
      onCancel={onClose}
      message="Deseja começar um novo projeto? Se o seu projeto atual não foi salvo, ele será perdido."
      confirmButtonLabel="Novo projeto"
    />
  );
};

export default NewFileConfirmationDialog;
