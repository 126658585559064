import React from "react";
import useLengthUnit from "hooks/useLengthUnit";
import { setLengthUnit } from "./actions";
import UnitControl from "./UnitControl";

const LengthUnitControl = () => {
  const selectedLengthUnit = useLengthUnit();

  return (
    <UnitControl
      unitOptions={["cm", "m"]}
      selectedUnit={selectedLengthUnit}
      action={setLengthUnit}
    />
  );
};

export default LengthUnitControl;
