import { call, put, takeLatest } from "redux-saga/effects";
import request from "utils/request";
import {
  STRUCTURAL_MODEL_REQUEST,
  STRUCTURAL_MODEL_SUCCESS,
  STRUCTURAL_MODEL_FAILURE,
} from "./constants";
import { getAuthHeader } from "../Utils/Auth"

export function* fetchStructuralModel({ params }) {
  try {
    const authHeader = yield call(getAuthHeader);

    const results = yield call(request, "/api/r3beams", {
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(params),
    });

    yield put({
      results,
      type: STRUCTURAL_MODEL_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: STRUCTURAL_MODEL_FAILURE,
      status: err.status,
    });
  }
}

export function* watchfetchStructuralModel() {
  yield takeLatest(STRUCTURAL_MODEL_REQUEST, fetchStructuralModel);
}

export default [watchfetchStructuralModel];
