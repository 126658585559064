import React from "react";
import ReactDOM from "react-dom";
import Amplify, { Auth } from "aws-amplify";
import ReactGA from "react-ga";
import "sanitize.css/sanitize.css";
import "tachyons/css/tachyons.min.css";
import "./global.css";
import "./animations.css";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import config from "./aws-exports";

const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN;

Amplify.configure(config[REACT_APP_DOMAIN]);

async function initializeGA() {
  const testMode = process.env.NODE_ENV === "development";
  ReactGA.initialize("UA-121797383-1", {
    debug: false,
    testMode: testMode,
  });
  try {
    // if there is a logged-in user, we can set the session with its userId
    // so we can identify the user across multiple devices.
    const user = await Auth.currentAuthenticatedUser();
    const userId = user.attributes.sub;
    ReactGA.set({ userId: userId });
  } catch {
    // No logged-in user
  }
}

initializeGA();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
