import { take, call, put, takeLatest, select } from "redux-saga/effects";
import request from "utils/request";
import {
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
} from "./constants";

export function* sendEmail({ type, params }) {
  try {
    const results = yield call(request, "/api/email", {
      method: "POST",
      body: JSON.stringify({ ...params }),
    });

    yield put({
      type: SEND_EMAIL_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: SEND_EMAIL_FAILURE,
      status: err.status,
    });
  }
}

export function* watchSendEmail() {
  yield takeLatest(SEND_EMAIL_REQUEST, sendEmail);
}

export default [watchSendEmail];
