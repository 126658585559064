export const PILECAP_REQUEST = "PILECAP_REQUEST";
export const PILECAP_SUCCESS = "PILECAP_SUCCESS";
export const PILECAP_FAILURE = "PILECAP_FAILURE";
export const PILECAP_DXF_REQUEST = "PILECAP_DXF_REQUEST";
export const PILECAP_DXF_SUCCESS = "PILECAP_DXF_SUCCESS";
export const PILECAP_DXF_FAILURE = "PILECAP_DXF_FAILURE";
export const PILECAP_SHOW_TIES = "PILECAP_SHOW_TIES";
export const PILECAP_SHOW_SKIN = "PILECAP_SHOW_SKIN";
export const PILECAP_SHOW_TOP_MESH = "PILECAP_SHOW_TOP_MESH";
export const PILECAP_SHOW_BOTTOM_MESH = "PILECAP_SHOW_BOTTOM_MESH";
export const PILECAP_SET_SHOW = "PILECAP_SET_SHOW";
export const PILECAP_COORDINATES_REQUEST = "PILECAP_COORDINATES_REQUEST";
export const PILECAP_COORDINATES_SUCCESS = "PILECAP_COORDINATES_SUCCESS";
export const PILECAP_FIELD_SET = "PILECAP_FIELD_SET";
export const PILECAP_FIELD_PUSH = "PILECAP_FIELD_PUSH";
export const PILECAP_FIELD_DELETE = "PILECAP_FIELD_DELETE";
