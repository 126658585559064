import React from "react";

const SIZE = 50;

const Spinner = () => {
  return (
    <svg width={SIZE} height={SIZE} viewBox="-5 -5 10 10">
      <rect className="spinner" x={-4} y={-4} width={8} height={8} />
    </svg>
  );
};

export default Spinner;
