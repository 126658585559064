import * as immutable from "object-path-immutable";
import {
  GET_RESULTS_ITEM_SUCCESS,
  NEW_FILE,
} from "containers/Toolbar/constants";
import {
  STRUCTURAL_MODEL_REQUEST,
  STRUCTURAL_MODEL_SUCCESS,
  STRUCTURAL_MODEL_FAILURE,
  STRUCTURAL_MODEL_FIELD_SET,
  STRUCTURAL_MODEL_NEW_MODEL,
  STRUCTURAL_MODEL_FIELD_PUSH,
  STRUCTURAL_MODEL_FIELD_DELETE,
  STRUCTURAL_MODEL_SECTION_FIELD_SET,
  STRUCTURAL_MODEL_SECTION_TYPE_SET,
  STRUCTURAL_MODEL_REMOVE_SECTION,
  STRUCTURAL_MODEL_ADD_SECTION,
  STRUCTURAL_MODEL_ADD_LOAD_CASE,
  STRUCTURAL_MODEL_REMOVE_LOAD_CASE,
  STRUCTURAL_MODEL_REMOVE_MEMBER,
  STRUCTURAL_MODEL_REMOVE_NODE,
  STRUCTURAL_MODEL_REMOVE_COMBINATION,
} from "./constants";
import { DEFAULT_SECTIONS, getDefaultSection } from "./defaultSections";
import { getSectionProps } from "./sectionProps";
import { nodeBeingUsedByAnyMember } from "./utils";
import { MOMENT } from "./results-options";
import example from "./example";

const NEW_LOAD_CASE_NAME = "Nome do carregamento";

const initialState = {
  error: null,
  fetching: false,
  results: {},
  selectedNode: null,
  selectedMember: null,
  selectedSection: 0,
  showResults: false,
  selectedLoadCase: 0,
  showCombinations: false,
  selectedCombination: 0,
  mode: null, // null, addNode, addMember
  selectedResult: MOMENT,
  nodes: [],
  members: [],
  nodeForces: [],
  memberLoads: [],
  sections: [getDefaultSection("r")],
  loadCases: [],
  combinations: [],
  // ...example,
};

export default function structuralModelReducer(state = initialState, action) {
  switch (action.type) {
    case STRUCTURAL_MODEL_REQUEST:
      return {
        ...state,
        error: null,
        fetching: true,
      };
    case STRUCTURAL_MODEL_SUCCESS:
      return {
        ...state,
        showResults: true,
        error: null,
        fetching: false,
        results: action.results,
      };
    case STRUCTURAL_MODEL_FAILURE:
      return {
        ...state,
        error: true,
        fetching: false,
      };

    case STRUCTURAL_MODEL_FIELD_SET:
      return immutable.set(state, action.path, action.value);
    case STRUCTURAL_MODEL_FIELD_PUSH:
      return immutable.push(state, action.path, action.value);
    case STRUCTURAL_MODEL_FIELD_DELETE:
      return immutable.del(state, action.path);

    case STRUCTURAL_MODEL_SECTION_FIELD_SET:
      return immutable.update(
        state,
        `sections.${action.sectionId}`,
        (section) => {
          const updatedParams = {
            ...section.params,
            [action.field]: action.value,
          };
          return {
            ...section,
            params: updatedParams,
            props: getSectionProps(section.type, updatedParams),
          };
        }
      );

    case STRUCTURAL_MODEL_SECTION_TYPE_SET:
      return immutable.set(state, `sections.${action.sectionId}`, {
        type: action.value,
        params: DEFAULT_SECTIONS[action.value],
        props: getSectionProps(action.value, DEFAULT_SECTIONS[action.value]),
      });

    case STRUCTURAL_MODEL_REMOVE_SECTION:
      return {
        ...state,
        selectedSection: Math.max(0, action.sectionId - 1),
        members: updateObjectsThatUsesRemovedIndex(
          state.members,
          "sectionId",
          action.sectionId
        ),
        sections: removeIndexFromArray(state.sections, action.sectionId),
      };

    case STRUCTURAL_MODEL_ADD_SECTION:
      return {
        ...state,
        selectedSection: state.sections.length,
        sections: [...state.sections, getDefaultSection("r")],
      };

    case STRUCTURAL_MODEL_ADD_LOAD_CASE:
      return {
        ...state,
        loadCases: [...state.loadCases, NEW_LOAD_CASE_NAME],
        combinations: state.combinations.map((comb) => ({
          ...comb,
          factors: [...comb.factors, 0],
        })),
      };

    case STRUCTURAL_MODEL_REMOVE_LOAD_CASE:
      return {
        ...state,
        selectedLoadCase: 0,
        loadCases: removeIndexFromArray(state.loadCases, action.index),
        combinations: state.combinations.map((comb) => ({
          ...comb,
          factors: removeIndexFromArray(comb.factors, action.index),
        })),
        nodeForces: removeObjectsThatUsesRemovedIndex(
          state.nodeForces,
          "loadCase",
          action.index
        ),
        memberLoads: removeObjectsThatUsesRemovedIndex(
          state.memberLoads,
          "loadCase",
          action.index
        ),
      };

    case STRUCTURAL_MODEL_REMOVE_MEMBER:
      if (action.index === null) {
        return state;
      }

      return {
        ...state,
        selectedMember: null,
        members: removeIndexFromArray(state.members, action.index),
        memberLoads: removeObjectsThatUsesRemovedIndex(
          state.memberLoads,
          "member",
          action.index
        ),
      };

    case STRUCTURAL_MODEL_REMOVE_NODE:
      if (action.index === null) {
        return state;
      }

      if (nodeBeingUsedByAnyMember(state.members, action.index)) {
        return state;
      }

      return {
        ...state,
        selectedNode: null,
        nodes: removeIndexFromArray(state.nodes, action.index),
        members: updateObjectsThatUsesRemovedIndex(
          updateObjectsThatUsesRemovedIndex(state.members, "n1", action.index),
          "n2",
          action.index
        ),
        nodeForces: removeObjectsThatUsesRemovedIndex(
          state.nodeForces,
          "node",
          action.index
        ),
      };

    case STRUCTURAL_MODEL_REMOVE_COMBINATION:
      return {
        ...immutable.del(state, `combinations.${action.index}`),
        selectedCombination: 0,
        showCombinations:
          state.combinations.length === 1 ? false : state.showCombinations,
      };

    case STRUCTURAL_MODEL_NEW_MODEL:
      return {
        ...state,
        error: null,
        fetching: false,
        results: {},
        nodes: [],
        members: [],
        nodeForces: [],
        memberLoads: [],
        combs: [],
      };

    case GET_RESULTS_ITEM_SUCCESS: {
      const {
        response: { type, results_data },
      } = action;

      if (type !== "STRUCTURAL-MODEL") {
        return state;
      }

      return {
        ...state,
        results: results_data,
        nodes: results_data.input.nodes,
        members: results_data.input.members,
        nodeForces: results_data.input.nodeForces,
        memberLoads: results_data.input.memberLoads,
        sections: results_data.input.sections,
        loadCases: results_data.input.loadCases,
        combinations: results_data.input.combinations,
      };
    }

    case NEW_FILE: {
      if (action.results_type === "STRUCTURAL-MODEL") {
        return initialState;
      }
      return state;
    }

    default:
      return state;
  }
}

const removeIndexFromArray = (array, indexToRemove) =>
  array.filter((_, index) => index !== indexToRemove);

const removeObjectsThatUsesRemovedIndex = (array, key, removedIndex) => {
  // first, remove objects whose key uses the removed index
  const filtered = array.filter((obj) => obj[key] !== removedIndex);

  // then, update remaining ones
  return filtered.map((obj) => {
    if (obj[key] > removedIndex) {
      return {
        ...obj,
        [key]: obj[key] - 1,
      };
    }

    return obj;
  });
};

const updateObjectsThatUsesRemovedIndex = (array, key, removedIndex) => {
  return array.map((obj) => {
    if (obj[key] >= removedIndex) {
      return {
        ...obj,
        [key]: Math.max(obj[key] - 1, 0),
      };
    }

    return obj;
  });
};
