import {
  SAVE_RESULTS_REQUEST,
  UPDATE_RESULTS_REQUEST,
  LOAD_RESULTS_REQUEST,
  GET_RESULTS_ITEM_REQUEST,
  DELETE_RESULTS_ITEM_REQUEST,
  NEW_FILE,
} from "./constants";

export function saveResults(results) {
  return {
    results,
    type: SAVE_RESULTS_REQUEST,
  };
}

export function updateResults(results) {
  return {
    results,
    type: UPDATE_RESULTS_REQUEST,
  };
}

export function loadResultsByType(results_type) {
  return {
    results_type,
    type: LOAD_RESULTS_REQUEST,
  };
}

export function getResultsItem(results_id) {
  return {
    results_id,
    type: GET_RESULTS_ITEM_REQUEST,
  };
}

export function deleteResultsItem(results_id) {
  return {
    results_id,
    type: DELETE_RESULTS_ITEM_REQUEST,
  };
}

export function newFile(results_type) {
  return {
    results_type,
    type: NEW_FILE,
  };
}
