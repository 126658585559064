export const STRUCTURAL_MODEL_REQUEST = "STRUCTURAL_MODEL_REQUEST";
export const STRUCTURAL_MODEL_SUCCESS = "STRUCTURAL_MODEL_SUCCESS";
export const STRUCTURAL_MODEL_FAILURE = "STRUCTURAL_MODEL_FAILURE";
export const STRUCTURAL_MODEL_FIELD_SET = "STRUCTURAL_MODEL_FIELD_SET";
export const STRUCTURAL_MODEL_FIELD_PUSH = "STRUCTURAL_MODEL_FIELD_PUSH";
export const STRUCTURAL_MODEL_FIELD_DELETE = "STRUCTURAL_MODEL_FIELD_DELETE";
export const STRUCTURAL_MODEL_NEW_MODEL = "STRUCTURAL_MODEL_NEW_MODEL";
export const STRUCTURAL_MODEL_SECTION_FIELD_SET =
  "STRUCTURAL_MODEL_SECTION_FIELD_SET";
export const STRUCTURAL_MODEL_SECTION_TYPE_SET =
  "STRUCTURAL_MODEL_SECTION_TYPE_SET";
export const STRUCTURAL_MODEL_REMOVE_SECTION =
  "STRUCTURAL_MODEL_REMOVE_SECTION";
export const STRUCTURAL_MODEL_ADD_SECTION = "STRUCTURAL_MODEL_ADD_SECTION";
export const STRUCTURAL_MODEL_ADD_LOAD_CASE = "STRUCTURAL_MODEL_ADD_LOAD_CASE";
export const STRUCTURAL_MODEL_REMOVE_LOAD_CASE =
  "STRUCTURAL_MODEL_REMOVE_LOAD_CASE";
export const STRUCTURAL_MODEL_REMOVE_MEMBER = "STRUCTURAL_MODEL_REMOVE_MEMBER";
export const STRUCTURAL_MODEL_REMOVE_NODE = "STRUCTURAL_MODEL_REMOVE_NODE";
export const STRUCTURAL_MODEL_REMOVE_COMBINATION =
  "STRUCTURAL_MODEL_REMOVE_COMBINATION";
