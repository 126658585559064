import React, { useState, useEffect } from "react";
import Dialog from "new-ui/Dialog";
import Spinner from "new-ui/LoadSpinner/Spinner";

const DELAY = 250; //ms
// if the loading time is fast enough, there is no need to show the loading
// dialog, this prevents unnecessary blinks in the screen.

const LoadingDialog = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setOpen(true), DELAY);

    return () => clearTimeout(timeout);
  });

  if (!open) {
    return null;
  }

  return (
    <Dialog>
      <div
        className="h-100 w-100 flex justify-center items-center"
        style={{ cursor: "wait" }}
      >
        <Spinner />
      </div>
    </Dialog>
  );
};

export default LoadingDialog;
