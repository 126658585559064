import React from "react";
import ButtonBase from "./ButtonBase";

const ToggleButton = ({ isSelected, onClick, children, className }) => {
  return (
    <ButtonBase
      className={`pa0 min-w-tap ba ${
        isSelected ? "b--primary bw1" : "b--border"
      } ${className}`}
      onClick={onClick}
    >
      {children}
    </ButtonBase>
  );
};

export default ToggleButton;
