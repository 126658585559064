import React from "react";

const TextInput = ({ value, label, placeholder, onChange }) => (
  <div className="">
    <label htmlFor={label}>{label}</label>
    <input
      id={label}
      className="w-100 ba db lh-tap ph3 pv0 outline-0 ba focus mt2 mb3 b"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
  </div>
);

export default TextInput;
