import React from "react";
import ButtonBase from "./ButtonBase";
import getClassName from "./style";

const MiniButton = ({ children, variant, disabled, onClick }) => {
  return (
    <ButtonBase
      className={`pa0 min-w-tap ${getClassName(variant, disabled)}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </ButtonBase>
  );
};

export default MiniButton;
