import { useState, useEffect } from "react";

const SMALL = 480;
const LARGE = 960;

const getBreakPoint = (windowWidth) => {
  if (windowWidth <= SMALL) {
    return "small";
  }

  if (windowWidth > SMALL && windowWidth <= LARGE) {
    return "medium";
  }

  return "large";
};

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });

  return getBreakPoint(windowSize);
};

export default useWindowSize;
