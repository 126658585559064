export const MOMENT = "MOMENT";
export const SHEAR = "SHEAR";
export const AXIAL = "AXIAL";
export const DISPLACEMENT = "DISPLACEMENT";
export const REACTIONS = "REACTIONS";

// API results keys
export const getDiagramName = (selectedResult) =>
  ({
    [MOMENT]: "m_diagram",
    [SHEAR]: "v_diagram",
    [AXIAL]: "n_diagram",
  }[selectedResult]);

export const W_DIAGRAM = "w_diagram";
export const U_DIAGRAM = "u_diagram";
export const BLOCKED_REACTIONS = "blocked_reactions";
export const SPRING_REACTIONS = "spring_reactions";
