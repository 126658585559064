import { getSectionProps } from "./sectionProps";

const RECTANGULAR_SECTION_PARAMS = {
  b: 0.2,
  h: 0.5,
  E: 25000,
};

const CIRCULAR_SECTION_PARAMS = {
  r: 0.25,
  E: 25000,
};

const T_SECTION_PARAMS = {
  bw: 0.2,
  h: 0.5,
  bf: 1.0,
  hf: 0.1,
  E: 25000,
};

const GENERIC_SECTION_PARAMS = {
  A: 1.5,
  I: 0.5,
  E: 25000,
};

export const DEFAULT_SECTIONS = {
  r: RECTANGULAR_SECTION_PARAMS,
  c: CIRCULAR_SECTION_PARAMS,
  t: T_SECTION_PARAMS,
  g: GENERIC_SECTION_PARAMS,
};

export const getDefaultSection = (sectionType) => ({
  type: sectionType,
  params: DEFAULT_SECTIONS[sectionType],
  props: getSectionProps(sectionType, DEFAULT_SECTIONS[sectionType]),
});
