const cognitoConfigByDomain = {
  local: {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_cgKoJTagk",
    aws_user_pools_web_client_id: "9i9ji4fm6d63gu0q5pi8n3qpn",
    oauth: {
      domain: "estruto-dev.auth.us-east-1.amazoncognito.com",
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: "http://localhost:3000/",
      redirectSignOut: "http://localhost:3000/",
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
  },
  development: {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_cgKoJTagk",
    aws_user_pools_web_client_id: "9i9ji4fm6d63gu0q5pi8n3qpn",
    oauth: {
      domain: "estruto-dev.auth.us-east-1.amazoncognito.com",
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: "https://estruto-staging.herokuapp.com/",
      redirectSignOut: "https://estruto-staging.herokuapp.com/",
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
  },
  production: {
    aws_project_region: "us-east-1",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_nizRegBff",
    aws_user_pools_web_client_id: "kjeknt9sh0o1f679qqoqdd21p",
    oauth: {
      domain: "estruto-prod.auth.us-east-1.amazoncognito.com",
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: "https://www.estruto.com/",
      redirectSignOut: "https://www.estruto.com/",
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
  },
};

export default cognitoConfigByDomain;
