import { take, call, put, takeLatest, select } from "redux-saga/effects";
import request from "utils/request";
import {
  PILECAP_REQUEST,
  PILECAP_COORDINATES_REQUEST,
  PILECAP_SUCCESS,
  PILECAP_COORDINATES_SUCCESS,
  PILECAP_FAILURE,
  PILECAP_DXF_REQUEST,
} from "./constants";
import FileSaver from "file-saver";
import { getAuthHeader } from "../Utils/Auth"

export function* fetchPileCap({ type, params }) {
  try {
    const authHeader = yield call(getAuthHeader);

    const results = yield call(request, "/api/pilecaps", {
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ ...params }),
    });

    yield put({
      results,
      type: PILECAP_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: PILECAP_FAILURE,
      status: err.status,
    });
  }
}

export function* watchFetchPileCap() {
  yield takeLatest(PILECAP_REQUEST, fetchPileCap);
}

export function* downloadDxf({ type, results }) {
  try {
    const response = yield call(request, "/api/pilecaps/dxf", {
      method: "POST",
      body: JSON.stringify({ ...results, views: null }),
    });
    FileSaver.saveAs(response, "bloco.dxf");
  } catch (err) {
    console.log("err:", err);
  }
}

export function* watchDownloadDxf() {
  yield takeLatest(PILECAP_DXF_REQUEST, downloadDxf);
}

export function* fetchPileCoordinates({ type, params }) {
  try {
    const results = yield call(request, "/api/pilecaps/pile_coordinates", {
      method: "POST",
      body: JSON.stringify({ ...params }),
    });

    yield put({
      results,
      type: PILECAP_COORDINATES_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: PILECAP_FAILURE,
      status: err.status,
    });
  }
}

export function* watchFetchPileCoordinates() {
  yield takeLatest(PILECAP_COORDINATES_REQUEST, fetchPileCoordinates);
}

export default [watchFetchPileCap, watchFetchPileCoordinates, watchDownloadDxf];
