import { createStore, applyMiddleware, compose } from "redux";
import { fromJS, Iterable } from "immutable";
import { routerMiddleware } from "react-router-redux";
import createSagaMiddleware from "redux-saga";
import createReducer from "./reducers";

import emailSagas from "containers/Contact/sagas";
import subscribeSagas from "containers/Subscribe/sagas";
import saveSagas from "containers/Toolbar/sagas";
import sectionSagas from "containers/Section/sagas";
import pileCapSagas from "containers/PileCap/sagas";
import structuralModelSagas from "containers/StructuralModel/sagas";
import beamSagas from "containers/Beam/sagas";

const containerSagas = [
  subscribeSagas,
  emailSagas,
  saveSagas,
  pileCapSagas,
  sectionSagas,
  beamSagas,
  structuralModelSagas,
];

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  if (process.env.NODE_ENV === "development") {
    const { createLogger } = require("redux-logger");

    const stateTransformer = (state) => {
      if (Iterable.isIterable(state)) return state.toJS();
      else return state;
    };

    middlewares.push(
      createLogger({
        stateTransformer,
        collapsed: true,
      })
    );
  }

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== "production" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    composeEnhancers(...enhancers)
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  containerSagas.forEach((sagas) => sagas.map(store.runSaga));

  if (module.hot) {
    module.hot.accept("./reducers", () => {
      import("./reducers").then((reducerModule) => {
        const createReducers = reducerModule.default;
        const nextReducers = createReducers();

        store.replaceReducer(nextReducers);
      });
    });
  }

  return store;
}
