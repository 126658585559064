import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SubscribeForm from "./SubscribeForm";
import MiniButton from "new-ui/Button/MiniButton";
import IconClose from "new-ui/Icons/IconClose";
import useUser from "containers/User/hooks/useUser";

const OPEN_DELAY = 3 * 60 * 1000;

function SubscribePopup() {
  const [open, setOpen] = useState(false);
  const { isAuthenticated } = useUser();
  const { registered, error } = useSelector((state) => state.get("subscribe"));

  useEffect(() => {
    const openPopupTimeout = setTimeout(
      () => setOpen(!isAuthenticated),
      OPEN_DELAY
    );

    return () => clearTimeout(openPopupTimeout);
  }, [isAuthenticated]);

  if (!open) {
    return null;
  }

  return (
    <div
      className="fixed bottom-0 left-0 ph3 z-max w-100 mw6-l"
      style={{ height: 350 }}
    >
      <div className="bg-foreground ba bb-0 b--primary pa3 h-100">
        <div className="mb4">
          <MiniButton onClick={() => setOpen(false)}>
            <IconClose />
          </MiniButton>
        </div>
        {registered && <SuccessMessage />}
        {error && <ErrorMessage />}
        {!registered && !error && <SubscribeForm />}
      </div>
    </div>
  );
}

const SuccessMessage = () => <p>Email cadastrado com sucesso. Obrigado.</p>;

const ErrorMessage = () => <p>Erro ao cadastrar o email.</p>;

export default SubscribePopup;
