import {
  SAVE_RESULTS_REQUEST,
  SAVE_RESULTS_FAILURE,
  SAVE_RESULTS_SUCCESS,
  LOAD_RESULTS_SUCCESS,
  LOAD_RESULTS_FAILURE,
  LOAD_RESULTS_REQUEST,
  UPDATE_RESULTS_REQUEST,
  UPDATE_RESULTS_SUCCESS,
  UPDATE_RESULTS_FAILURE,
  GET_RESULTS_ITEM_REQUEST,
  GET_RESULTS_ITEM_SUCCESS,
  GET_RESULTS_ITEM_FAILURE,
  DELETE_RESULTS_ITEM_REQUEST,
  DELETE_RESULTS_ITEM_SUCCESS,
  DELETE_RESULTS_ITEM_FAILURE,
  NEW_FILE,
} from "./constants";

const initialState = {
  loading: false,
  savedResults: [],
  resultsId: null,
};

export default function saveReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_RESULTS_REQUEST:
    case LOAD_RESULTS_REQUEST:
    case UPDATE_RESULTS_REQUEST:
    case DELETE_RESULTS_ITEM_REQUEST:
    case GET_RESULTS_ITEM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SAVE_RESULTS_SUCCESS:
    case UPDATE_RESULTS_SUCCESS:
    case GET_RESULTS_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        resultsId: action.response.results_id,
      };
    case SAVE_RESULTS_FAILURE:
    case LOAD_RESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        results_id: null,
        savedResults: [],
      };
    case UPDATE_RESULTS_FAILURE:
    case GET_RESULTS_ITEM_FAILURE:
    case DELETE_RESULTS_ITEM_SUCCESS:
    case DELETE_RESULTS_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case LOAD_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        savedResults: action.response.user_results_by_type,
      };

    case NEW_FILE:
      return initialState;

    default:
      return state;
  }
}
