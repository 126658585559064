import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from "./constants";

const initialState = {
  error: null,
  fetching: false,
  registered: false,
};

export default function emailReducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER_REQUEST:
      return {
        ...state,
        error: null,
        fetching: true,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        registered: true,
        error: null,
        fetching: false,
      };
    case REGISTER_FAILURE:
      return {
        ...state,
        registered: false,
        error:
          action.status === 400
            ? "Email já cadastrado. Obrigado."
            : "Erro ao registrar email de contato. Tente novamente mais tarde.",
        fetching: false,
      };
    default:
      return state;
  }
}
