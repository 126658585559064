import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MiniButton from "new-ui/Button/MiniButton";
import IconRemove from "new-ui/Icons/IconRemove";
import {
  loadResultsByType,
  getResultsItem,
  deleteResultsItem,
} from "./actions";
import LoadingDialog from "./LoadingDialog";
import ConfirmationDialog from "./ConfirmationDialog";

const SavedFiles = ({
  resultsType,
  allowDelete = false,
  loadCallback = () => null,
}) => {
  const dispatch = useDispatch();
  const [fileToDelete, setFileToDelete] = useState(null);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(true);
  const { savedResults, loading } = useSelector((state) => state.get("save"));

  useEffect(() => {
    if (forceUpdate) {
      dispatch(loadResultsByType(resultsType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate]);

  useEffect(() => {
    if (!loading) {
      setForceUpdate(false);
    }
  }, [loading]);

  useEffect(() => {
    setForceUpdate(true);
  }, [resultsType]);

  const loadResults = (file) => {
    dispatch(getResultsItem(file.results_id));
    loadCallback();
  };

  const handleDelete = (file) => {
    setShowConfirmationDialog(true);
    setFileToDelete(file);
  };

  const handleCancelDelete = () => {
    setFileToDelete(null);
    setShowConfirmationDialog(false);
  };

  const handleConfirmDelete = () => {
    deleteFile(fileToDelete.results_id);
    setFileToDelete(null);
    setShowConfirmationDialog(false);
    setForceUpdate(true);
  };

  const deleteFile = (resultsId) => {
    dispatch(deleteResultsItem(resultsId));
  };

  if (loading) {
    return <LoadingDialog />;
  }

  if (savedResults.length === 0) {
    return <p className="lh-tap">Você ainda não possui arquivos salvos.</p>;
  }

  return (
    <React.Fragment>
      <SavedFilesTable
        savedResults={savedResults}
        onClick={loadResults}
        onDelete={handleDelete}
        allowDelete={allowDelete}
      />
      {showConfirmationDialog && (
        <ConfirmationDialog
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          message="Tem certeza que deseja deletar este arquivo? Essa ação é
          irreversível."
          confirmButtonLabel="Deletar arquivo"
        />
      )}
    </React.Fragment>
  );
};

const SavedFilesTable = ({ savedResults, onClick, allowDelete, onDelete }) => (
  <table className="w-100 tl">
    <thead>
      <tr>
        <FixedTh>Nome do arquivo</FixedTh>
        <FixedTh>Modificado em</FixedTh>
        {allowDelete && <FixedTh />}
      </tr>
    </thead>
    <tbody>
      {savedResults.map((file) => {
        const updatedAtDate = file.updated_at;
        const updatedAtFormatedString = new Date(
          updatedAtDate
        ).toLocaleDateString("pt-BR");
        return (
          <tr key={file.results_id} className="bb b--border">
            <td className="w-100">
              <button
                className="ph3 min-h-tap tl w-100 b pointer outline-0"
                onClick={() => onClick(file)}
              >
                {file.results_name}
              </button>
            </td>
            <td className="ph3">{updatedAtFormatedString}</td>
            {allowDelete && (
              <td>
                <MiniButton variant="text" onClick={() => onDelete(file)}>
                  <IconRemove />
                </MiniButton>
              </td>
            )}
          </tr>
        );
      })}
    </tbody>
  </table>
);

const FixedTh = ({ children }) => (
  <th
    className="min-h-tap pa3 bg-background bb b--border normal nowrap"
    style={{ position: "sticky", top: 0 }}
  >
    {children}
  </th>
);

export default SavedFiles;
