import {
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_FAILURE,
} from "./constants";

const initialState = {
  error: null,
  fetching: false,
  sent: false,
};

export default function emailReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_EMAIL_REQUEST:
      return {
        ...state,
        error: null,
        fetching: true,
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sent: true,
        error: null,
        fetching: false,
      };
    case SEND_EMAIL_FAILURE:
      return {
        ...state,
        sent: false,
        error: "Erro ao enviar email de contato",
        fetching: false,
      };
    default:
      return state;
  }
}
