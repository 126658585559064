import React from "react";
import { Route, Redirect } from "react-router-dom";
import LoadingDialog from "containers/Toolbar/LoadingDialog";

const BlockedRoute = ({ children, block, loading, redirectTo, ...rest }) => {
  if (loading) {
    return <LoadingDialog />;
  }

  if (block) {
    return <Redirect to={redirectTo} />;
  }

  return <Route {...rest}>{children}</Route>;
};

export default BlockedRoute;
