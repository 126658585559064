import { combineReducers } from "redux-immutable";
import { fromJS } from "immutable";
import { LOCATION_CHANGE } from "react-router-redux";

import emailReducer from "containers/Contact/reducer";
import subscribeReducer from "containers/Subscribe/reducer";
import unitsReducer from "containers/Units/reducer";
import saveReducer from "containers/Toolbar/reducer";
import userReducer from "containers/User/reducer";
import pilecapReducer from "containers/PileCap/reducer";
import sectionReducer from "containers/Section/reducer";
import beamReducer from "containers/Beam/reducer";
import structuralModelReducer from "containers/StructuralModel/reducer";

/**
 * Route reducer
 */
const routeInitialState = fromJS({
  locationBeforeTransitions: null,
});

function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.merge({
        locationBeforeTransitions: action.payload,
      });
    default:
      return state;
  }
}

export default function createReducer() {
  return combineReducers({
    route: routeReducer,
    units: unitsReducer,
    subscribe: subscribeReducer,
    save: saveReducer,
    user: userReducer,
    section: sectionReducer,
    pilecap: pilecapReducer,
    email: emailReducer,
    beam: beamReducer,
    structuralModel: structuralModelReducer,
  });
}
