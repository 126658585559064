import { LENGHT_UNIT_SET, FORCE_UNIT_SET } from "./constants";

const initialState = {
  length: "cm",
  force: "kN",
};

export default function unitsReducer(state = initialState, action) {
  switch (action.type) {
    case LENGHT_UNIT_SET:
      return {
        ...state,
        length: action.unit,
      };

    case FORCE_UNIT_SET:
      return {
        ...state,
        force: action.unit,
      };

    default:
      return state;
  }
}
