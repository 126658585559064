import React from "react";

const ButtonBase = ({
  className,
  children,
  disabled,
  onClick,
  type = "button",
  noPadding = false,
}) => {
  return (
    <button
      className={`min-h-tap b outline-0 ${className}`}
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        padding: noPadding && 0,
      }}
      disabled={disabled}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};

export default ButtonBase;
