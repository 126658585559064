import React from "react";
import LengthUnitControl from "containers/Units/LengthUnitControl";
import ForceUnitControl from "containers/Units/ForceUnitControl";

const UnitsBar = () => {
  return (
    <div className="flex">
      <div className="mr3">
        <LengthUnitControl />
      </div>

      <div className="mr3">
        <ForceUnitControl />
      </div>
    </div>
  );
};

export default UnitsBar;
