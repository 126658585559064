export const SAVE_RESULTS_REQUEST = "SAVE_RESULTS_REQUEST";
export const SAVE_RESULTS_SUCCESS = "SAVE_RESULTS_SUCCESS";
export const SAVE_RESULTS_FAILURE = "SAVE_RESULTS_FAILURE";
export const UPDATE_RESULTS_REQUEST = "UPDATE_RESULTS_REQUEST";
export const UPDATE_RESULTS_SUCCESS = "UPDATE_RESULTS_SUCCESS";
export const UPDATE_RESULTS_FAILURE = "UPDATE_RESULTS_FAILURE";
export const LOAD_RESULTS_REQUEST = "LOAD_RESULTS_REQUEST";
export const LOAD_RESULTS_SUCCESS = "LOAD_RESULTS_SUCCESS";
export const LOAD_RESULTS_FAILURE = "LOAD_RESULTS_FAILURE";
export const GET_RESULTS_ITEM_REQUEST = "GET_RESULTS_ITEM_REQUEST";
export const GET_RESULTS_ITEM_SUCCESS = "GET_RESULTS_ITEM_SUCESS";
export const GET_RESULTS_ITEM_FAILURE = "GET_RESULTS_ITEM_FAILURE";
export const DELETE_RESULTS_ITEM_REQUEST = "DELETE_RESULTS_ITEM_REQUEST";
export const DELETE_RESULTS_ITEM_SUCCESS = "DELETE_RESULTS_ITEM_SUCCESS";
export const DELETE_RESULTS_ITEM_FAILURE = "DELETE_RESULTS_ITEM_FAILURE";
export const NEW_FILE = "NEW_FILE";
