import { SET_USER } from "./constants";

const initialState = {
  user: null,
  isAuthenticated: false,
  isSubscriptionValid: false,
  loading: true,
};

export default function saveReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user,
        isAuthenticated: action.user !== null,
        isSubscriptionValid: action.isSubscriptionValid,
        loading: false,
      };

    default:
      return state;
  }
}
