import React from "react";
import Toolbar from "containers/Toolbar";
import { orange, grey, red } from "@material-ui/core/colors";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import WarningsContainer from "containers/Module/WarningsContainer";
import TempWarning from "containers/Utils/TempWarning";

const theme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: grey,
    error: red,
  },
  strokeWidth: {
    1: 1 * 0.002,
    2: 2 * 0.002,
    3: 3 * 0.002,
    4: 4 * 0.002,
    5: 5 * 0.002,
  },
  maxWidth: {
    maxWidth: 800,
  },
});

const WithTempFrame = (WrappedComponent, resultsType) => () => (
  <MuiThemeProvider theme={theme}>
    <div className="h-100 flex flex-column">
      <Toolbar resultsType={resultsType} />
      <main className="w-100 flex flex-1">
        <WrappedComponent />
        {/* <WarningsContainer>
          <TempWarning />
        </WarningsContainer> */}
      </main>
    </div>
  </MuiThemeProvider>
);

export default WithTempFrame;
