import React from "react";
import useHandleSaveFile from "./hooks/useHandleSaveFile";
import FileButton from "./FileButton";
import useHasResultsId from "./hooks/useHasResultsId";

const FileBar = ({
  resultsType,
  openNewFileConfirmationDialog,
  openOpenFileDialog,
  openSaveAsDialog,
}) => {
  const handleSaveFile = useHandleSaveFile(resultsType);
  const hasResultsId = useHasResultsId();

  return (
    <div>
      <FileButton onClick={openNewFileConfirmationDialog}>Novo</FileButton>
      <FileButton onClick={openOpenFileDialog}>Abrir</FileButton>
      <FileButton disabled={!hasResultsId} onClick={handleSaveFile}>
        Salvar
      </FileButton>
      <FileButton onClick={openSaveAsDialog}>Salvar como</FileButton>
    </div>
  );
};

export default FileBar;
