import React from "react";
import MiniButton from "new-ui/Button/MiniButton";
import IconClose from "new-ui/Icons/IconClose";

const FormHeader = ({ children, formLabel, onClose }) => {
  return (
    <div className="w-100 pa3 flex items-center bg-background bb b--border">
      <MiniButton onClick={onClose}>
        <IconClose />
      </MiniButton>
      <h1 className="dib ma0 ml3 f2 fw9">{formLabel}</h1>
      {children}
    </div>
  );
};
export default FormHeader;
