import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { saveResults } from "./actions";
import Dialog from "new-ui/Dialog";
import FormHeader from "new-ui/PageLayout/FormHeader";
import Button from "new-ui/Button/Button";
import TextInput from "new-ui/Input/TextInput";

// TODO: resultsType is not a good name for that
const getModuleName = (resultsType) => {
  return {
    PILECAP: "pilecap",
    SECTION: "section",
    BEAM: "beam",
    "STRUCTURAL-MODEL": "structuralModel",
  }[resultsType];
};

const SaveAsDialog = ({ resultsType, onClose }) => {
  const [filename, setFilename] = useState("");
  const dispatch = useDispatch();
  const moduleName = getModuleName(resultsType);
  const resultsSelector = (state) => state.get(moduleName).results;
  const results = useSelector(resultsSelector);

  const handleSaveAs = (event) => {
    event.preventDefault();

    dispatch(
      saveResults({
        results_data: results,
        results_type: resultsType,
        results_name: filename,
      })
    );
    onClose();
  };

  const canSave = filename !== "";

  return (
    <Dialog>
      <div className="bg-background br-ns w-100 mw6 h-100">
        <FormHeader formLabel={"Salvar como"} onClose={onClose} />
        <form className="pa3" onSubmit={handleSaveAs}>
          <TextInput
            value={filename}
            label="Nome do arquivo"
            placeholder="Nome do arquivo"
            onChange={(e) => setFilename(e.target.value)}
          />
          <div className="tr">
            <Button variant="contained" disabled={!canSave} type="submit">
              Salvar
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default SaveAsDialog;
