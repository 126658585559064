import { call, put, takeLatest } from "redux-saga/effects";
import FileSaver from "file-saver";
import request from "utils/request";
import {
  BEAM_REQUEST,
  BEAM_SUCCESS,
  BEAM_FAILURE,
  BEAM_DXF_REQUEST,
} from "./constants";
import { getAuthHeader } from "../Utils/Auth"

export function* fetchBeam({ params }) {
  try {
    const authHeader = yield call(getAuthHeader);

    const results = yield call(request, "/api/beams", {
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ ...params }),
    });

    yield put({
      results,
      type: BEAM_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: BEAM_FAILURE,
      status: err.status,
      error: err,
    });
  }
}

export function* watchFetchBeam() {
  yield takeLatest(BEAM_REQUEST, fetchBeam);
}

export function* downloadDxf({ type, params }) {
  try {
    const response = yield call(request, "/api/beams/dxf", {
      method: "POST",
      body: JSON.stringify({ ...params }),
    });
    FileSaver.saveAs(response, "viga.dxf");
  } catch (err) {
    console.log("err:", err);
  }
}

export function* watchDownloadDxf() {
  yield takeLatest(BEAM_DXF_REQUEST, downloadDxf);
}

export default [watchFetchBeam, watchDownloadDxf];
