import { call, put, takeLatest } from "redux-saga/effects";
import request from "utils/request";
import { Auth } from "aws-amplify";
import {
  SAVE_RESULTS_REQUEST,
  SAVE_RESULTS_FAILURE,
  SAVE_RESULTS_SUCCESS,
  LOAD_RESULTS_SUCCESS,
  LOAD_RESULTS_FAILURE,
  LOAD_RESULTS_REQUEST,
  UPDATE_RESULTS_REQUEST,
  UPDATE_RESULTS_SUCCESS,
  UPDATE_RESULTS_FAILURE,
  GET_RESULTS_ITEM_REQUEST,
  GET_RESULTS_ITEM_SUCCESS,
  GET_RESULTS_ITEM_FAILURE,
  DELETE_RESULTS_ITEM_REQUEST,
  DELETE_RESULTS_ITEM_SUCCESS,
  DELETE_RESULTS_ITEM_FAILURE,
} from "./constants";
import { getAuthHeader } from "../Utils/Auth"

export function* saveResults({ results }) {
  try {
    const authHeader = yield call(getAuthHeader);

    const response = yield call(request, "/api/results", {
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ ...results }),
    });

    yield put({
      response,
      type: SAVE_RESULTS_SUCCESS,
    });
  } catch (err) {
    console.log(err);

    yield put({
      type: SAVE_RESULTS_FAILURE,
      status: err.status,
      error: err,
    });
  }
}

export function* watchSaveResults() {
  yield takeLatest(SAVE_RESULTS_REQUEST, saveResults);
}

export function* updateResults({ results }) {
  const authHeader = yield call(getAuthHeader);

  try {
    const response = yield call(request, `/api/results/${results.results_id}`, {
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify({
        ...results,
      }),
    });

    yield put({
      response,
      type: UPDATE_RESULTS_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: UPDATE_RESULTS_FAILURE,
      status: err.status,
    });
  }
}

export function* watchUpdateResults() {
  yield takeLatest(UPDATE_RESULTS_REQUEST, updateResults);
}

export function* loadResultsByType({ results_type }) {
  const authHeader = yield call(getAuthHeader);
  try {
    const response = yield call(request, "/api/results/search-by-type", {
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ results_type }),
    });

    yield put({
      response,
      type: LOAD_RESULTS_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: LOAD_RESULTS_FAILURE,
      status: err.status,
    });
  }
}

export function* watchLoadResultsByType() {
  yield takeLatest(LOAD_RESULTS_REQUEST, loadResultsByType);
}

export function* getResultsItem({ results_id }) {
  const authHeader = yield call(getAuthHeader);

  try {
    const response = yield call(request, `/api/results/${results_id}`, {
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
      method: "GET",
    });

    yield put({
      response,
      type: GET_RESULTS_ITEM_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: GET_RESULTS_ITEM_FAILURE,
      status: err.status,
    });
  }
}

export function* watchGetResultsItem() {
  yield takeLatest(GET_RESULTS_ITEM_REQUEST, getResultsItem);
}

export function* deleteResultsItem({ results_id }) {
  const authHeader = yield call(getAuthHeader);

  try {
    const response = yield call(request, `/api/results/${results_id}`, {
      headers: {
        Authorization: authHeader,
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });

    yield put({
      response,
      type: DELETE_RESULTS_ITEM_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: DELETE_RESULTS_ITEM_FAILURE,
      status: err.status,
    });
  }
}

export function* watchDeleteResultsItem() {
  yield takeLatest(DELETE_RESULTS_ITEM_REQUEST, deleteResultsItem);
}

export default [
  watchSaveResults,
  watchUpdateResults,
  watchLoadResultsByType,
  watchGetResultsItem,
  watchDeleteResultsItem,
];
