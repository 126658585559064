import React from "react";

const BackgroundOverlay = () => (
  <div
    className="fixed top-0 right-0 w-100 h-100 bg-background o-70"
    style={{ zIndex: -1 }}
  />
);

export default BackgroundOverlay;
