import React from "react";
import LinkButton from "new-ui/Button/LinkButton";
import Button from "new-ui/Button/Button";
import MiniButton from "new-ui/Button/MiniButton";
import IconClose from "new-ui/Icons/IconClose";
import NavigationLinkButton from "new-ui/Button/NavigationLinkButton";
import BackgroundOverlay from "new-ui/Dialog/BackgroundOverlay";
import UnitsBar from "./UnitsBar";
import useHandleSaveFile from "./hooks/useHandleSaveFile";
import useHasResultsId from "./hooks/useHasResultsId";

const MenuDialog = ({
  resultsType,
  openNewFileConfirmationDialog,
  openOpenFileDialog,
  openSaveAsDialog,
  onClose,
}) => {
  const handleSaveFile = useHandleSaveFile(resultsType);
  const hasResultsId = useHasResultsId();

  const handleSaveFileAndCloseMenuDialog = () => {
    handleSaveFile();
    onClose();
  };

  return (
    <aside className="fixed top-0 left-0 bg-background z-2 w-100 h-100 outline mw6">
      <BackgroundOverlay />
      <header className="br b--border pv3 ph3 tc flex items-center bb b-border">
        <MiniButton variant="contained" onClick={() => onClose()}>
          <IconClose />
        </MiniButton>
        <span className="ph3">Estruto</span>
      </header>
      <div className="pa3">
        <section>
          <LinkButton variant="text" path="/menu">
            Voltar para o Menu Inicial
          </LinkButton>
        </section>
        <section>
          <MenuSubtitle>Arquivo</MenuSubtitle>
          <MenuItemButton onClick={openNewFileConfirmationDialog}>
            Novo
          </MenuItemButton>
          <MenuItemButton onClick={openOpenFileDialog}>Abrir</MenuItemButton>
          <MenuItemButton
            disabled={!hasResultsId}
            onClick={handleSaveFileAndCloseMenuDialog}
          >
            Salvar
          </MenuItemButton>
          <MenuItemButton onClick={openSaveAsDialog}>
            Salvar como
          </MenuItemButton>
        </section>
        <section>
          <MenuSubtitle>Unidades</MenuSubtitle>
          <div className="pl3">
            <UnitsBar />
          </div>
        </section>
        <section>
          <MenuSubtitle>Contato</MenuSubtitle>
          <NavigationLinkButton path="/contato">Enviar feedback</NavigationLinkButton>
        </section>
      </div>
    </aside>
  );
};

const MenuItemButton = ({ children, disabled, onClick }) => (
  <Button
    className="db w-100 tl"
    variant="text"
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </Button>
);

const MenuSubtitle = ({ children }) => (
  <h2 className="f2 mv4 db normal">{children}</h2>
);

export default MenuDialog;
