import React, { useState } from "react";
import { useSelector } from "react-redux";
import useWindowSize from "hooks/useWindowSize";
import Button from "new-ui/Button/Button";
import FileBar from "./FileBar";
import UnitsBar from "./UnitsBar";
import OpenFileDialog from "./OpenFileDialog";
import SaveAsDialog from "./SaveAsDialog";
import LoadingDialog from "./LoadingDialog";
import MenuDialog from "./MenuDialog";
import NewFileConfirmationDialog from "./NewFileConfirmationDialog";

const Toolbar = ({ resultsType }) => {
  const [openDialogName, setOpenDialogName] = useState(null);
  const windowSize = useWindowSize();
  const { loading } = useSelector((state) => state.get("save"));

  const openMenuDialog = () => setOpenDialogName("MENU");
  const openOpenFileDialog = () => setOpenDialogName("OPEN");
  const openSaveAsDialog = () => setOpenDialogName("SAVE_AS");
  const openNewFileConfirmationDialog = () => setOpenDialogName("NEW");
  const closeDialogs = () => setOpenDialogName(null);

  return (
    <React.Fragment>
      {/* Dialogs */}
      {loading && <LoadingDialog />}
      {openDialogName === "NEW" && (
        <NewFileConfirmationDialog
          resultsType={resultsType}
          onClose={closeDialogs}
        />
      )}
      {openDialogName === "OPEN" && (
        <OpenFileDialog resultsType={resultsType} onClose={closeDialogs} />
      )}
      {openDialogName === "SAVE_AS" && (
        <SaveAsDialog resultsType={resultsType} onClose={closeDialogs} />
      )}
      {openDialogName === "MENU" && (
        <MenuDialog
          resultsType={resultsType}
          openNewFileConfirmationDialog={openNewFileConfirmationDialog}
          openOpenFileDialog={openOpenFileDialog}
          openSaveAsDialog={openSaveAsDialog}
          onClose={closeDialogs}
        />
      )}
      {/* Toolbar */}
      <header className="bb b--border flex">
        <div className="br b--border pv3 ph3-l tc">
          <Button className="w4-l" variant="text" onClick={openMenuDialog}>
            Menu
          </Button>
        </div>
        {windowSize !== "small" && (
          <div className="flex-1 pv3 flex">
            <FileBar
              resultsType={resultsType}
              openNewFileConfirmationDialog={openNewFileConfirmationDialog}
              openOpenFileDialog={openOpenFileDialog}
              openSaveAsDialog={openSaveAsDialog}
            />
          </div>
        )}
        {windowSize === "large" && (
          <div className="pv3">
            <UnitsBar />
          </div>
        )}
      </header>
    </React.Fragment>
  );
};

export default Toolbar;
