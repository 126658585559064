import { useEffect } from "react";
import { Auth, Hub } from "aws-amplify";
import { useDispatch } from "react-redux";
import { setUser } from "../actions";

const useSetUser = () => {
  const dispatch = useDispatch();

  const dispatchUser = async () => {
    const user = await getUser();
    const isSubscriptionValid = await getSubscriptionIsValid(user);

    dispatch(setUser({ user, isSubscriptionValid }));
  };

  useEffect(() => {
    // On mount we check if there's an authenticated user
    dispatchUser();
    // need to happen only once, so we pass the empty array []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // On mount we add a listener to auth events
    Hub.listen("auth", dispatchUser);

    // On unmount we remove the listener to prevent memory leak
    return function cleanup() {
      Hub.remove("auth", dispatchUser);
    };
  });
};

const getUser = async () => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    return null;
  }
};

const getSubscriptionIsValid = async (user) => {
  let isSubscriptionValid = false;
  if (user !== null) {
    try {
      const token = user.signInUserSession.idToken.jwtToken;
      isSubscriptionValid = await fetchIsSubscriptionValid(token);
    } catch (error) {}
  }

  return isSubscriptionValid;
};

const fetchIsSubscriptionValid = async (token) => {
  // review
  const response = await fetch("/api/subscriptions/protected", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status !== 200) {
    throw Error("No valid subscription");
  }

  return true;
};

export default useSetUser;
