import React from "react";
import Button from "new-ui/Button/Button";

const FileButton = ({ children, onClick, disabled }) => {
  return (
    <Button variant="text" onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
};

export default FileButton;
