export function getArea(sectionType, sectionParams) {
  switch (sectionType) {
    case "r": {
      const { b, h } = sectionParams;
      return b * h;
    }
    case "t": {
      const { bw, h, bf, hf } = sectionParams;
      const webArea = bw * (h - hf);
      const flatArea = bf * hf;
      return webArea + flatArea;
    }
    case "c": {
      const { r } = sectionParams;
      return Math.PI * r ** 2;
    }
    case "g": {
      return parseFloat(sectionParams.A);
    }
    default:
      return 0;
  }
}

export function getCentroid(sectionType, sectionParams) {
  switch (sectionType) {
    case "r": {
      const { h } = sectionParams;
      return h / 2;
    }
    case "t": {
      const { bw, h, bf, hf } = sectionParams;
      const webArea = bw * (h - hf);
      const webYcg = (h - hf) / 2;
      const flatArea = bf * hf;
      const flatYcg = h - hf / 2;
      return (webArea * webYcg + flatArea * flatYcg) / (webArea + flatArea);
    }
    case "c": {
      const { r } = sectionParams;
      return parseFloat(r);
    }
    case "g": {
      return null;
    }
    default:
      return 0;
  }
}

export function getInertia(sectionType, sectionParams) {
  switch (sectionType) {
    case "r": {
      const { b, h } = sectionParams;
      return (b * h ** 3) / 12;
    }
    case "t": {
      const { bw, h, bf, hf } = sectionParams;
      const area = getArea(sectionType, sectionParams);
      const ycg = getCentroid(sectionType, sectionParams);
      return (
        (bf * hf ** 3) / 12 +
        bf * hf * (h - hf / 2) ** 2 +
        (bw * (h - hf) ** 3) / 3.0 -
        area * ycg ** 2
      );
    }
    case "c": {
      const { r } = sectionParams;
      return (Math.PI * r ** 4) / 4;
    }
    case "g": {
      return parseFloat(sectionParams.I);
    }
    default:
      return 0;
  }
}

export function getSectionProps(sectionType, sectionParams) {
  return {
    A: getArea(sectionType, sectionParams),
    ycg: getCentroid(sectionType, sectionParams),
    I: getInertia(sectionType, sectionParams),
  };
}
