import React from "react";
import Dialog from "new-ui/Dialog";
import FormHeader from "new-ui/PageLayout/FormHeader";
import SavedFiles from "./SavedFiles";

const OpenFileDialog = ({ resultsType, onClose }) => {
  return (
    <Dialog>
      <div className="bg-background br-ns w-100 mw6 h-100 flex flex-column">
        <FormHeader formLabel="Abrir arquivo" onClose={onClose} />
        <div className="overflow-auto flex-1 ph3">
          <SavedFiles resultsType={resultsType} loadCallback={onClose} />
        </div>
      </div>
    </Dialog>
  );
};

export default OpenFileDialog;
