import React from "react";
import UnitButton from "./UnitButton";
import { useDispatch } from "react-redux";

const UnitSelector = ({ unitOptions, selectedUnit, action }) => {
  const dispatch = useDispatch();

  return unitOptions.map((unit) => {
    return (
      <UnitButton
        key={unit}
        unit={unit}
        selectedUnit={selectedUnit}
        onClick={(unit) => dispatch(action(unit))}
      />
    );
  });
};

export default UnitSelector;
