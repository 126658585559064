import React from "react";
import useForceUnit from "hooks/useForceUnit";
import { setForceUnit } from "./actions";
import UnitControl from "./UnitControl";

const ForceUnitControl = () => {
  const selectedForceUnit = useForceUnit();

  return (
    <UnitControl
      unitOptions={["kN", "tf"]}
      selectedUnit={selectedForceUnit}
      action={setForceUnit}
    />
  );
};

export default ForceUnitControl;
