import { useSelector, useDispatch } from "react-redux";
import { updateResults } from "../actions";

// TODO: resultsType is not a good name for that
const getModuleName = (resultsType) => {
  return {
    PILECAP: "pilecap",
    SECTION: "section",
    BEAM: "beam",
    "STRUCTURAL-MODEL": "structuralModel",
  }[resultsType];
};

const useHandleSaveFile = (resultsType) => {
  const dispatch = useDispatch();
  const { resultsId } = useSelector((state) => state.get("save"));
  const moduleName = getModuleName(resultsType);
  const resultsSelector = (state) => state.get(moduleName).results;
  const results = useSelector(resultsSelector);

  const handleSave = () => {
    dispatch(
      updateResults({
        results_data: results,
        results_id: resultsId,
        results_type: resultsType,
      })
    );
  };

  return handleSave;
};

export default useHandleSaveFile;
