const getClassName = (variant = "outlined", disabled) => {
  return (
    `${disabled && "o-50"} ` +
    {
      contained: "bg-primary c-background ba b--primary",
      outlined: "c-primary ba b--primary",
      outlinedWithBackground: "c-primary ba b--primary bg-background",
      text: "bn c-primary",
      textNegative: "bn c-background",
      secondary: "bg-secondary c-background bn",
    }[variant]
  );
};

export default getClassName;
