import React from "react";
import useUser from "containers/User/hooks/useUser";
import BlockedRoute from "./BlockedRoute";

const AuthenticatedRoute = ({ children, ...rest }) => {
  const { loading, isAuthenticated } = useUser();

  return (
    <BlockedRoute
      block={!isAuthenticated}
      loading={loading}
      redirectTo="/"
      {...rest}
    >
      {children}
    </BlockedRoute>
  );
};

export default AuthenticatedRoute;
