import * as immutable from "object-path-immutable";
import {
  GET_RESULTS_ITEM_SUCCESS,
  NEW_FILE,
} from "containers/Toolbar/constants";
import {
  PILECAP_REQUEST,
  PILECAP_SUCCESS,
  PILECAP_FAILURE,
  PILECAP_SET_SHOW,
  PILECAP_COORDINATES_REQUEST,
  PILECAP_COORDINATES_SUCCESS,
  PILECAP_FIELD_SET,
  PILECAP_FIELD_PUSH,
  PILECAP_FIELD_DELETE,
} from "./constants";

const initialState = {
  columnsInput: [
    {
      normal: "",
      momentX: 0,
      momentY: 0,
      columnBx: "",
      columnBy: "",
      xg: 0,
      yg: 0,
    },
  ],
  pileSettings: {
    pileCapacity: "",
    pilePhi: 0.4,
    nPiles: null,
    lengthUnit: "m",
    forceUnit: "kN",
  },
  settings: {
    fck: 30,
    coverage: 0.05,
    strutAngle: 45,
    gammaF: 1.4,
    gammaN: 1.2,
    unit: "m",
    usePiles: false,
    spacingPileFiMultiple: 3,
    useExtendedPileDiameter: true,
  },
  adjusts: {
    tieFi: 12.5 / 1000,
    columnRebarFi: 16 / 1000,
    height: "",
  },
  modes: {
    pileMode: "auto", // auto, manual or preset
    heightMode: "auto", // auto or manual
    tieFiMode: "auto", // auto or manual
  },
  piles: [],
  results: {},
  error: null,
  fetching: false,
  showTies: true,
  showSkin: true,
  showTopMesh: false,
  showBottomMesh: false,
  showStruts: true,
  showDimLines: true,
  showColumnsForces: true,
  showReactions: true,
};

export default function pileCapReducer(state = initialState, action) {
  switch (action.type) {
    case PILECAP_REQUEST:
      return {
        ...state,
        error: null,
        fetching: true,
      };
    case PILECAP_SUCCESS:
      return {
        ...state,
        error: null,
        fetching: false,
        results: {
          ...action.results.design,
          input: action.results.input,
        },
        pileSettings: {
          ...state.pileSettings,
          nPiles: action.results.design.n_piles,
        },
        adjusts: {
          tieFi: action.results.design.ties.fi,
          columnRebarFi: action.results.design.column_fi,
          height: action.results.design.height,
        },
        piles: action.results.design.piles.map((pile) => ({
          x: pile.xg,
          y: pile.yg,
        })),
      };
    case PILECAP_FAILURE:
      return {
        ...state,
        error: `Erro ao calcular bloco ${action.status}`,
        fetching: false,
      };
    case PILECAP_SET_SHOW:
      return {
        ...state,
        [action.field]: action.show,
      };
    case PILECAP_COORDINATES_REQUEST:
      return {
        ...state,
        error: null,
        fetching: true,
      };
    case PILECAP_COORDINATES_SUCCESS:
      return {
        ...state,
        error: null,
        fetching: false,
        piles: action.results.piles.map((pile) => ({ x: pile[0], y: pile[1] })),
      };
    case PILECAP_FIELD_SET:
      return immutable.set(state, action.path, action.value);
    case PILECAP_FIELD_PUSH:
      return immutable.push(state, action.path, action.value);
    case PILECAP_FIELD_DELETE:
      return immutable.del(state, action.path);

    case GET_RESULTS_ITEM_SUCCESS: {
      if (action.response.type !== "PILECAP") {
        return state;
      }

      return {
        ...state,
        error: null,
        fetching: false,
        results: {
          ...action.response.results_data,
        },
        columnsInput: action.response.results_data.input.columnsInput,
        pileSettings: {
          ...action.response.results_data.input.pileSettings,
          nPiles: action.response.results_data.n_piles,
        },
        settings: {
          ...action.response.results_data.input.settings,
        },
        adjusts: {
          ...action.response.results_data.input.adjusts,
          tieFi: action.response.results_data.ties.fi,
          columnRebarFi: action.response.results_data.column_fi,
          height: action.response.results_data.height,
        },
        modes: {
          ...action.response.results_data.input.modes,
        },
        piles: action.response.results_data.piles.map((pile) => ({
          x: pile.xg,
          y: pile.yg,
        })),
      };
    }

    case NEW_FILE: {
      if (action.results_type === "PILECAP") {
        return initialState;
      }
      return state;
    }

    default:
      return state;
  }
}
