import React from "react";
import { Link } from "react-router-dom";
import Button from "./Button";

const LinkButton = ({
  className,
  children,
  variant,
  path,
  disabled = false,
  noPadding = false,
}) => (
  <Link
    className="pointer"
    style={{
      pointerEvents: disabled && "none",
    }}
    to={path}
  >
    <Button
      variant={variant}
      className={className}
      disabled={disabled}
      noPadding={noPadding}
    >
      {children}
    </Button>
  </Link>
);

export default LinkButton;
